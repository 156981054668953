<template>
  <div>
    <va-modal
      width="100%"
      v-model="showInvokeMethodModal"
      size="large"
      @ok="saveInvokingMethod()"
      @cancel="cancelInvokingMethod()"
      fixed-layout
      max-width="1500px"
      no-outside-dismiss
    >
      <slot style="margin: 50px;">
        <div class="va-table">
          <table  style="width: 100%;" class="va-table-responsive">
            <thead>
              <tr>
                <th class="content-th" width="20%">Organization</th>
                <th class="content-th" width="20%">Pack Mode</th>
                <th class="content-th" width="20%">Device Typpe</th>
                <th class="content-th" width="20%">Token Mode</th>
                <th class="content-th">Screens</th>
                <th class="content-th">Service Category</th>
                <th class="content-th">Method</th>
                <th class="content-th">Invoke Method</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(field,i) in invokeFields" :key="field.id">
                <td>
                  <va-select
                    v-if="isCreate || (isUpdate && !field.showFlag)"
                    placeholder="Organization"
                    v-model="field.org_name"
                    searchable
                    textBy="org_name"
                    :options="org_list"
                  />
                  <va-input
                    v-if="isUpdate && field.showFlag"
                    placeholder="Organization"
                    v-model="field.org_name"
                    textBy="org_name"
                    :disabled="true"
                  />
                </td>
                <td>
                  <va-select
                    style="width: 180px;"
                    v-if="isCreate || (isUpdate && !field.showFlag)"
                    placeholder="Mode"
                    v-model="field.mode"
                    textBy="mode"
                    multiple
                    :options="mode_list"
                  />
                  <va-input
                    v-if="isUpdate && field.showFlag"
                    placeholder="Mode"
                    v-model="field.mode"
                    textBy="mode"
                    :disabled="true"
                  />
                </td>
                <td>
                  <va-select
                    v-if="isCreate || (isUpdate && !field.showFlag)"
                    placeholder="Device Type"
                    v-model="field.device_type"
                    textBy="device_data"
                    :options="device_list"
                  />
                  <va-input
                    v-if="isUpdate && field.showFlag"
                    placeholder="Device Type"
                    v-model="field.device_type"
                    textBy="device_data"
                    :disabled="true"
                  />
                </td>
                <td>
                  <va-select
                    v-if="isCreate || (isUpdate && !field.showFlag)"
                    placeholder="Token Mode"
                    v-model="field.token_mode"
                    textBy="token_data"
                    :options="token_list"
                  />
                  <va-input
                    v-if="isUpdate && field.showFlag"
                    placeholder="Token Mode"
                    v-model="field.token_mode"
                    textBy="token_data"
                    :disabled="true"
                  />
                </td>
                <td>
                  <va-input
                    v-if="isCreate || isUpdate"
                    v-model="field.invoke_screen"
                    type="number"
                    placeholder="Screens"
                  />
                </td>
                <td>
                  <va-select
                    v-if="isCreate || (isUpdate && !field.showFlag)"
                    placeholder="Service Category"
                    v-model="field.service_category"
                    textBy="id"
                    :options="category_data"
                  />
                  <va-input
                    v-if="isUpdate && field.showFlag"
                    placeholder="Service Category"
                    v-model="field.service_category"
                    :disabled="true"
                  />
                </td>
                <td>
                  <va-select
                    v-if="isCreate || (isUpdate && !field.showFlag)"
                    placeholder="Method"
                    v-model="field.method"
                    textBy="id"
                    @input="getMethodList(field.method, i)"
                    :options="method_arr"
                  />
                  <va-input
                    v-if="isUpdate && field.showFlag"
                    placeholder="Invoke Method"
                    v-model="field.method"
                    :disabled="true"
                  />
                </td>
                <td>
                  <va-select
                    v-if="isCreate || (isUpdate && !field.showFlag)"
                    placeholder="Invoke Method"
                    v-model="field.invoking_method"
                    textBy="method_data"
                    :options="invoke_method_list"
                  />
                  <va-input
                    v-if="isUpdate && field.showFlag"
                    placeholder="Invoke Method"
                    v-model="field.invoking_method"
                    textBy="method_data"
                    :disabled="true"
                  />
                </td>
                <td>
                  <va-button
                    v-if="invokeFields.length != 1"
                    class="content_width toCenter"
                    flat color="danger"
                    @click.prevent="removeTechField(i)"
                    icon="entypo entypo-cancel" />
                </td>
                <td>
                  <va-button
                    v-if="invokeFields.length - 1 == i"
                    class="content_width toCenter"
                    flat color="primary"
                    @click.prevent="fields_length++"
                    icon="entypo entypo-plus" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </slot>
    </va-modal>
    <div v-if="isshowgrid">
      <va-card title="service provider List">
        <template slot="actions">
          <va-button color="success" icon="fa fa-plus" v-if="admin_role" @click="add()">Add</va-button>
        </template>
        <div class="row align--center">
          <div class="flex xs12 md6">
            <va-input class="va-input-search-container"
              :value="term"
              placeholder="search"
              @input="search"
              removable
            >
              <va-icon name="fa fa-search" slot="prepend" />
            </va-input>
          </div>
        </div>
        <va-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="parseInt(perPage)">
          <template slot="actions" slot-scope="props">
            <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
          </template>
        </va-data-table>
      </va-card>
    </div>
    <div v-else-if='isshowForm'>
      <div>
        <va-card v-if="isshowForm" :title="title">
          <template slot="actions">
            <va-button color="gray" @click="list()">List</va-button>
          </template>
          <div>
            <form>
              <span class="va-form-label va-form-required-label">Brand Name</span>
              <va-input
                v-model.trim="form_data.brand"
                placeholder="Enter Brand Name"
                :error="!!error_msg.brandErrors.length"
                :error-messages="error_msg.brandErrors"
              />
              <span class="va-form-label va-form-required-label">Company Name</span>
              <va-input
                v-model.trim="form_data.company_name"
                placeholder="Enter Company Name"
                :error="!!error_msg.companyErrors.length"
                :error-messages="error_msg.companyErrors"
              />
              <span class="va-form-label va-form-required-label">Service Category</span>
              <va-select
                v-model="form_data.service_category"
                textBy="id"
                multiple
                searchable
                placeholder="Select Service Category"
                :options="category_data"
                :error="!!error_msg.CategoryErrors.length"
                :error-messages="error_msg.CategoryErrors"
              />
              <span class="va-form-label va-form-required-label">Service Type</span>
              <va-select
                v-model="form_data.service_type"
                textBy="id"
                placeholder="Select Service Type"
                multiple
                :options="type_data"
                :error="!!error_msg.TypeErrors.length"
                :error-messages="error_msg.TypeErrors"
              />
              <span class="va-form-label va-form-required-label">Screen Type</span>
              <va-select
                v-model="form_data.screen_type"
                textBy="id"
                placeholder="Select Screen Type"
                @input="screenType(form_data.screen_type)"
                :options="screentype_data"
                :error="!!error_msg.ScreenTypeErrors.length"
                :error-messages="error_msg.ScreenTypeErrors"
              />
              <span v-if="flatFlag" class="va-form-label va-form-required-label">Screens</span>
              <va-input
                v-if="flatFlag"
                v-model.trim="form_data.screens"
                type="number"
                placeholder="Enter Screens"
                :error="!!error_msg.screenErrors.length"
                :error-messages="error_msg.screenErrors"
              />
              <span v-if="variableFlag" class="va-form-label va-form-required-label">Mobile Screens</span>
              <va-input
                v-if="variableFlag"
                v-model.trim="form_data.mobile_screens"
                type="number"
                placeholder="Enter Mobile Screens"
                :error="!!error_msg.mobileScreenErrors.length"
                :error-messages="error_msg.mobileScreenErrors"
              />
              <span v-if="variableFlag" class="va-form-label va-form-required-label">TV Screens</span>
              <va-input
                v-if="variableFlag"
                v-model.trim="form_data.tv_screens"
                type="number"
                placeholder="Enter  TV Screens"
                :error="!!error_msg.tvScreenErrors.length"
                :error-messages="error_msg.tvScreenErrors"
              />
              <span class="va-form-label va-form-required-label">Method</span>
              <va-select
                v-model="form_data.method"
                textBy="id"
                multiple
                placeholder="Select Method"
                @input="methodType(form_data.method)"
                :options="methodArr"
                :error="!!error_msg.methodErrors.length"
                :error-messages="error_msg.methodErrors"
              />
              <span class="va-form-label va-form-required-label">Default Method</span>
              <va-select
                v-model="form_data.default_method"
                textBy="id"
                placeholder="Select Default Method"
                :options="methodArr"
                :error="!!error_msg.defaultMethodErrors.length"
                :error-messages="error_msg.defaultMethodErrors"
              />
              <span v-if="linkFlag" class="va-form-label"><u>Default Settings:</u></span><br>
              <span v-if="linkFlag" class="va-form-label va-form-required-label">Link Field</span>
              <va-input
                v-if="linkFlag"
                v-model.trim="form_data.link_field"
                type="text"
                placeholder="Enter Default Link Field"
                :error="!!error_msg.linkFieldErrors.length"
                :error-messages="error_msg.linkFieldErrors"
              />
              <span v-if="linkFlag" class="va-form-label va-form-required-label">Link Format</span>
              <va-input
                v-if="linkFlag"
                v-model.trim="form_data.link_format"
                type="text"
                placeholder="Enter Default Link Format"
                :error="!!error_msg.linkFormatErrors.length"
                :error-messages="error_msg.linkFormatErrors"
              />
              <span v-if="linkFlag" class="va-form-label va-form-required-label">Invoking Method</span>
              <va-select
                v-if="linkFlag"
                placeholder="Select Invoking Method"
                v-model="form_data.invoking_method"
                textBy="id"
                :options="invokingMethodArr"
                :error="!!error_msg.invokingMethodErrors.length"
                :error-messages="error_msg.invokingMethodErrors"
              />
              <span v-if="linkFlag" class="va-form-label va-form-required-label">Default Token Mode</span>
              <va-select
                v-if="linkFlag"
                placeholder="Select Token Mode"
                v-model="form_data.default_token_mode"
                textBy="token_data"
                :options="token_list"
                :error="!!error_msg.defaultTokenModeErrors.length"
                :error-messages="error_msg.defaultTokenModeErrors"
              />
              <span v-if="linkFlag" class="va-form-label va-form-required-label">Default Screens</span>
              <va-input
                v-if="linkFlag"
                v-model="form_data.default_screen"
                type="number"
                placeholder="Enter Default Screens"
                :error="!!error_msg.defaultScreenErrors.length"
                :error-messages="error_msg.defaultScreenErrors"
              />
              <br/>
              <span v-if="linkFlag" class="va-form-label va-form-required-label">Web Link Field</span>
              <va-input
                v-if="linkFlag"
                v-model.trim="form_data.web_link_field"
                type="text"
                placeholder="Enter Web Link Field"
                :error="!!error_msg.webLinkFieldErrors.length"
                :error-messages="error_msg.webLinkFieldErrors"
              />
              <span v-if="linkFlag" class="va-form-label va-form-required-label">Web Link Format</span>
              <va-input
                v-if="linkFlag"
                v-model.trim="form_data.web_link_format"
                type="text"
                placeholder="Enter Web Link Format"
                :error="!!error_msg.webLinkFormatErrors.length"
                :error-messages="error_msg.webLinkFormatErrors"
              />
              <span v-if="linkFlag" class="va-form-label va-form-required-label">Mobile Native Link Field</span>
              <va-input
                v-if="linkFlag"
                v-model.trim="form_data.mobile_native_link_field"
                type="text"
                placeholder="Enter Mobile Native Link Field"
                :error="!!error_msg.mobileNativeLinkFieldErrors.length"
                :error-messages="error_msg.mobileNativeLinkFieldErrors"
              />
              <span v-if="linkFlag" class="va-form-label va-form-required-label">Mobile Native Link Format</span>
              <va-input
                v-if="linkFlag"
                v-model.trim="form_data.mobile_native_link_format"
                type="text"
                placeholder="Enter Mobile Native Link Format"
                :error="!!error_msg.mobileNativeLinkFormatErrors.length"
                :error-messages="error_msg.mobileNativeLinkFormatErrors"
              />
              <span v-if="linkFlag" class="va-form-label va-form-required-label">TV Native Link Field</span>
              <va-input
                v-if="linkFlag"
                v-model.trim="form_data.tv_native_link_field"
                type="text"
                placeholder="Enter TV Native Link Field"
                :error="!!error_msg.tvNativelinkFieldErrors.length"
                :error-messages="error_msg.tvNativelinkFieldErrors"
              />
              <span v-if="linkFlag" class="va-form-label va-form-required-label">TV Native Link Format</span>
              <va-input
                v-if="linkFlag"
                v-model.trim="form_data.tv_native_link_format"
                type="text"
                placeholder="Enter TV Native Link Format"
                :error="!!error_msg.tvNativelinkFormatErrors.length"
                :error-messages="error_msg.tvNativelinkFormatErrors"
              />
              <!-- <va-select
                v-if="linkFlag"
                placeholder="Select Invoking Method"
                v-model="form_data.invoking_method"
                textBy="id"
                @input="checkWeb(form_data.invoking_method)"
                :options="invokingMethodArr"
                :error="!!error_msg.invokingMethodErrors.length"
                :error-messages="error_msg.invokingMethodErrors"
              /> -->
              <span v-if="directFlag" class="va-form-label va-form-required-label">Direct Link Field</span>
              <va-input
                v-if="directFlag"
                v-model.trim="form_data.direct_link_field"
                type="text"
                placeholder="Enter Link Field"
                :error="!!error_msg.directLinkFieldErrors.length"
                :error-messages="error_msg.directLinkFieldErrors"
              />
              <span v-if="directFlag" class="va-form-label va-form-required-label">Direct Link Format</span>
              <va-input
                v-if="directFlag"
                v-model.trim="form_data.direct_link_format"
                type="text"
                placeholder="Enter Link Format"
                :error="!!error_msg.directLinkFormatErrors.length"
                :error-messages="error_msg.directLinkFormatErrors"
              />
              <va-button type="submit" @click.prevent="showInvokeModal()">
                Update Invoke Method
              </va-button>
              <br/>
              <br/>
              <div>
                <p v-if="linkFlag" class="va-form-label">Allow Sites</p>
                <textarea
                  v-if="linkFlag"
                  class="text_box"
                  v-model.trim="form_data.allow_sites"
                  placeholder="domain1.com,domain2.com,domain3.com"
                />
              </div>
              <span v-if="directFlag" class="va-form-label va-form-required-label">Play Format</span>
              <va-select
                v-if="directFlag"
                v-model="form_data.play_format"
                placeholder="Select Play Format"
                textBy="id"
                :options="play_format_arr"
                :error="!!error_msg.playFormatErrors.length"
                :error-messages="error_msg.playFormatErrors"
              />
              <va-checkbox
                v-if="directFlag"
                label="Encrypted"
                :value="form_data.encrypted"
                @input="isEncrypt(form_data.encrypted)"
              />
              <span v-if="directFlag && encryptedFlag" class="va-form-label va-form-required-label">DRM License URL</span>
              <va-input
                v-if="directFlag && encryptedFlag"
                v-model.trim="form_data.drm_license_url"
                label="DRM License URL *"
                type="text"
                placeholder="Enter DRM License URL"
                :error="!!error_msg.drmLicenseUrlErrors.length"
                :error-messages="error_msg.drmLicenseUrlErrors"
              />
              <va-checkbox
                label="Attach Logo"
                :value="form_data.attach_logo"
                @input="isLogo(form_data.attach_logo)"
              />
              <div class="flex xs12">
                <va-card
                  v-if="logoFlag"
                >
                  <span class="va-form-label va-form-required-label">Logo</span>
                  <va-file-upload
                    type="single"
                    dropzone
                    v-model="logo_file"
                  />
                </va-card>
              </div>
              <va-checkbox
                label="IPTV"
                :value="form_data.iptv"
                @input="isIptv(form_data.iptv)"
              />
              <span v-if="iptvFlag" class="va-form-label va-form-required-label">SMS Host</span>
              <va-input
                v-if="iptvFlag"
                v-model.trim="form_data.sms_host"
                type="text"
                placeholder="test.com or 1.1.1.1"
                :error="!!error_msg.smsHostErrors.length"
                :error-messages="error_msg.smsHostErrors"
              />
              <span v-if="iptvFlag" class="va-form-label va-form-required-label">SMS Port</span>
              <va-input
                v-if="iptvFlag"
                v-model.trim="form_data.sms_port"
                type="text"
                placeholder="Enter SMS Port"
                :error="!!error_msg.smsPortErrors.length"
                :error-messages="error_msg.smsPortErrors"
              />
              <span v-if="iptvFlag" class="va-form-label va-form-required-label">SMS Token</span>
              <va-input
                v-if="iptvFlag"
                v-model.trim="form_data.sms_token"
                type="text"
                placeholder="Enter SMS Token"
                :error="!!error_msg.smsTokenErrors.length"
                :error-messages="error_msg.smsTokenErrors"
              />
              <span v-if="iptvFlag" class="va-form-label va-form-required-label">Logo Domain</span>
              <va-input
                v-if="iptvFlag"
                v-model.trim="form_data.drm_domain"
                type="text"
                placeholder="(Eg:mso-ant.livye.com)"
                :error="!!error_msg.smsLogoDomainErrors.length"
                :error-messages="error_msg.smsLogoDomainErrors"
              />
              <div class="mt-3">
                <span class="va-form-label va-form-required-label">Provider Type</span>
                <fieldset class="row ml-1">
                  <va-radio-button
                    option="Independent"
                    v-model="provider_type"
                    label="Independent"
                  />
                  <va-radio-button
                    option="Hybrid"
                    v-model="provider_type"
                    label="Hybrid"
                  />
                </fieldset>                
              </div>
              <div class="d-flex justify--end mt-3">
                <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="list()">Cancel</va-button>
                <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createConfig()">Create</va-button>
                <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateConfig()">Update</va-button>
              </div>
            </form>
          </div>
        </va-card>
      </div>
    </div>
  </div>
</template>
<script>
import { debounce } from 'lodash'
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import REGEX from '../../i18n/regex_pattern'
Vue.use(vueResource)

export default {
  name: 'service_provider',
  data () {
    return {
      tempIinvokeFields: [],
      showInvokeMethodModal: false,
      term: null,
      perPage: 10,
      totalPages: 0,
      items: [],
      loading: false,
      message: '',
      category_data: [],
      methodArr: [{ id: 'Link' }, { id: 'Direct' }],
      screen_type: '',
      flatFlag: false,
      variableFlag: false,
      linkFlag: false,
      directFlag: false,
      logoFlag: false,
      iptvFlag: false,
      encryptedFlag: false,
      typeArray: [],
      screentype_data: [{ id: 'Flat' }, { id: 'Variable' }],
      invokingMethodArr: [{ id: 'Web' }, { id: 'Native' }],
      play_format_arr: [{ id: 'HLS' }, { id: 'DASH' }],
      provider_id: '',
      logo_file: [],
      method_arr: [{ id: 'Link' }, { id: 'Direct' }],
      form_data: {
        brand: '',
        iptv: false,
        company_name: '',
        service_category: [],
        service_type: [],
        screen_type: '',
        screens: '',
        mobile_screens: '',
        tv_screens: '',
        play_format: '',
        method: [],
        invoking_method: '',
        allow_sites: '',
        direct_link_field: '',
        direct_link_format: '',
        drm_license_url: '',
        sms_host: '',
        sms_port: '',
        sms_token: '',
        drm_domain: '',
        encrypted: false,
        attach_logo: false,
        web_link_field: '',
        web_link_format: '',
        mobile_native_link_format: '',
        mobile_native_link_field: '',
        tv_native_link_format: '',
        tv_native_link_field: '',
        link_field: '',
        link_format: '',
        default_screen: 1,
        default_token_mode: '',
        default_method: '',
      },
      error_msg: {
        option: true,
        brandErrors: [],
        companyErrors: [],
        CategoryErrors: [],
        TypeErrors: [],
        ScreenTypeErrors: [],
        screenErrors: [],
        mobileScreenErrors: [],
        methodErrors: [],
        tvScreenErrors: [],
        tvNativelinkFieldErrors: [],
        tvNativelinkFormatErrors: [],
        invokingMethodErrors: [],
        directLinkFormatErrors: [],
        directLinkFieldErrors: [],
        drmLicenseUrlErrors: [],
        logoUrlErrors: [],
        playFormatErrors: [],
        allowSitesErrors: [],
        smsHostErrors: [],
        smsPortErrors: [],
        smsTokenErrors: [],
        webLinkFormatErrors: [],
        webLinkFieldErrors: [],
        mobileNativeLinkFormatErrors: [],
        mobileNativeLinkFieldErrors: [],
        linkFieldErrors: [],
        linkFormatErrors: [],
        defaultScreenErrors: [],
        defaultTokenModeErrors: [],
        defaultMethodErrors: [],
        smsLogoDomainErrors: [],
      },
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      type_data: [],
      title: '',
      fields_length: 0,
      org_list: [],
      mode_list: [{ id: 1, mode: 'Monthly' }, { id: 2, mode: 'Quarterly' }, { id: 3, mode: 'Halfyearly' }, { id: 4, mode: 'Yearly' }],
      device_list: [{ id: 1, device_data: 'Tv' }, { id: 2, device_data: 'Mobile' }],
      invoke_method_list: [],
      token_list: [{ id: 1, token_data: 'Default' }, { id: 2, token_data: 'Yearly' }],
      invokeFields: [{ org_name: '', mode: [], device_type: '', method: '', token_mode: '', invoke_screen: 1, service_category: '', invoking_method: '' }],
      showFlag: false,
      admin_role: false,
      provider_type: ''
    }
  },
  watch: {
    fields_length () {
      this.invokeFields.push({ org_name: '', mode: [], device_type: '', method: '', token_mode: '', invoke_screen: 1, service_category: '', invoking_method: '' })
    },
  },
  computed: {
    checkScreenFields () {
      if ((this.form_data.screen_type.id === 'Flat') || (this.form_data.screen_type === 'Flat')) {
        this.error_msg.screenErrors = this.form_data.screens ? [] : ['Screens is required']
        return !this.error_msg.screenErrors.length
      } else if ((this.form_data.screen_type.id === 'Variable') || (this.form_data.screen_type === 'Variable')) {
        this.error_msg.mobileScreenErrors = this.form_data.mobile_screens ? [] : ['Mobile Screens is required']
        this.error_msg.tvScreenErrors = this.form_data.tv_screens ? [] : ['TV Screens is required']

        return !this.error_msg.mobileScreenErrors.length && !this.error_msg.tvScreenErrors.length
      }
    },
    checkMethodFields () {
      if (this.form_data.method.length == 2) {
        this.error_msg.webLinkFieldErrors = this.form_data.web_link_field ? [] : ['Web Link Field is required']
        this.error_msg.webLinkFormatErrors = this.form_data.web_link_format ? [] : ['Web Link Format is required']
        this.error_msg.mobileNativeLinkFieldErrors = this.form_data.mobile_native_link_field ? [] : ['Mobile Native Link Field is required']
        this.error_msg.mobileNativeLinkFormatErrors = this.form_data.mobile_native_link_format ? [] : ['Mobile Native Link Format is required']
        this.error_msg.tvNativelinkFieldErrors = this.form_data.tv_native_link_field ? [] : ['TV  Native Link Field is required']
        this.error_msg.tvNativelinkFormatErrors = this.form_data.tv_native_link_format ? [] : ['TV  Native Link Format is required']
        this.error_msg.directLinkFieldErrors = this.form_data.direct_link_field ? [] : ['Link Field is required']
        this.error_msg.directLinkFormatErrors = this.form_data.direct_link_format ? [] : ['Link Format is required']
        this.error_msg.playFormatErrors = this.form_data.play_format ? [] : ['Play Format is required']
        return !this.error_msg.webLinkFieldErrors.length && !this.error_msg.webLinkFormatErrors.length && !this.error_msg.mobileNativeLinkFieldErrors.length && !this.error_msg.mobileNativeLinkFormatErrors.length && !this.error_msg.tvNativelinkFieldErrors.length && !this.error_msg.tvNativelinkFormatErrors.length && !this.error_msg.directLinkFieldErrors.length && !this.error_msg.directLinkFormatErrors.length && !this.error_msg.playFormatErrors.length
      } else {
        if ((this.form_data.method[0].id === 'Link') || (this.form_data.method === 'Link')) {
          this.error_msg.webLinkFieldErrors = this.form_data.web_link_field ? [] : ['Web Link Field is required']
          this.error_msg.webLinkFormatErrors = this.form_data.web_link_format ? [] : ['Web Link Format is required']
          this.error_msg.mobileNativeLinkFieldErrors = this.form_data.mobile_native_link_field ? [] : ['Mobile Native Link Field is required']
          this.error_msg.mobileNativeLinkFormatErrors = this.form_data.mobile_native_link_format ? [] : ['Mobile Native Link Format is required']
          this.error_msg.tvNativelinkFieldErrors = this.form_data.tv_native_link_field ? [] : ['TV  Native Link Field is required']
          this.error_msg.tvNativelinkFormatErrors = this.form_data.tv_native_link_format ? [] : ['TV  Native Link Format is required']
          return !this.error_msg.webLinkFieldErrors.length && !this.error_msg.webLinkFormatErrors.length && !this.error_msg.mobileNativeLinkFieldErrors.length && !this.error_msg.mobileNativeLinkFormatErrors.length && !this.error_msg.tvNativelinkFieldErrors.length && !this.error_msg.tvNativelinkFormatErrors.length
        } else if ((this.form_data.method[0].id === 'Direct') || (this.form_data.method === 'Direct')) {
          this.error_msg.directLinkFieldErrors = this.form_data.direct_link_field ? [] : ['Link Field is required']
          this.error_msg.directLinkFormatErrors = this.form_data.direct_link_format ? [] : ['Link Format is required']
          this.error_msg.playFormatErrors = this.form_data.play_format ? [] : ['Play Format is required']
          return !this.error_msg.directLinkFieldErrors.length && !this.error_msg.directLinkFormatErrors.length && !this.error_msg.playFormatErrors.length
        }
      }
    },
    CheckIptv () {
      this.error_msg.smsHostErrors = this.form_data.sms_host ? [] : ['SMS Host is required']
      this.error_msg.smsPortErrors = this.form_data.sms_port ? [] : ['SMS Port is required']
      this.error_msg.smsTokenErrors = this.form_data.sms_token ? [] : ['SMS Token is required']
      this.error_msg.smsLogoDomainErrors = this.form_data.drm_domain ? [] : ['DRM Domain is required']
      return !this.error_msg.smsHostErrors.length && !this.error_msg.smsPortErrors.length && !this.error_msg.smsTokenErrors.length &&  !this.error_msg.smsLogoDomainErrors.length
    },
    Checkfile_upload () {
      if (this.logo_file[0]) {
        return true
      } else {
        Vue.notify({ text: 'please Upload images', type: 'error' })
        return false
      }
    },
    formReady () {
      return !this.error_msg.brandErrors.length && !this.error_msg.companyErrors.length && !this.error_msg.CategoryErrors.length && !this.error_msg.typeErrors.length && !this.error_msg.ScreenTypeErrors.length && !this.error_msg.methodErrors.length && !this.error_msg.defaultMethodErrors.length
    },
    fields () {
      return [{
        name: 'sno',
        title: 'S.NO',
        width: '6%',
      }, {
        name: '__slot:marker',
        width: '30px',
        dataClass: 'text-center',
      },
      {
        name: 'brand',
        title: 'Brand Name',
      },
      {
        name: 'company_name',
        title: 'Company Name',
      },
      {
        name: 'provider_type',
        title: 'Type'
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      return search(this.term, this.items)
    },
  },
  created () {
    this.readItems()
  },
  methods: {
    showInvokeModal () {
      this.showInvokeMethodModal = !this.showInvokeMethodModal
      this.tempIinvokeFields = _.clone(this.invokeFields, true)
    },
    saveInvokingMethod () {
      this.tempIinvokeFields = []
    },
    cancelInvokingMethod () {
      this.invokeFields = this.tempIinvokeFields
      this.tempIinvokeFields = []
    },
    removeTechField (index) {
      this.invokeFields = this.invokeFields.filter((_field, i) => i != index)
    },
    getMethodList (data, count) {
      var json = this.invokeFields[count]
      json.invoking_method = ''
      if (data.id == 'Link') {
        this.invoke_method_list = [{ id: 1, method_data: 'Web' }, { id: 2, method_data: 'Native' }]
      } else {
        this.invoke_method_list = [{ id: 1, method_data: 'Direct' }]
      }
    },
    readItems (page = 0) {
      this.loading = true
      this.isshowForm = false
      this.isshowgrid = true
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/user/'+Vue.$cookies.get('user-id')).then(resp => {
        this.admin_role = (resp.body.roles == 'ADMIN') ? true : false
        this.$http.get(config.menu.host + '/provider').then(response => {
          loader.hide()
          let index = 0
          this.items = response.body.map(function (item) {
            item.id = index++
            item.sno = index
            item.is_existing = true
            item.checkbox_value = false
            if(item.provider_type == null || item.provider_type == 'null') item.provider_type = ''; 
            return item
          })
          this.$http.get(config.menu.host + '/org').then(resp => {
            this.org_list = resp.body
            this.org_list.map(function (dta, i) {
              dta.id = i + 1
              dta.showFlag = false
            })
          })
          this.loading = false
          this.isshowgrid = true
        })
      })
    },
    createConfig () {
      this.reset()
      this.error_msg.brandErrors = this.form_data.brand ? [] : ['Brand is required']
      this.error_msg.companyErrors = this.form_data.company_name ? [] : ['Company Name is required']
      this.error_msg.CategoryErrors = this.form_data.service_category.length ? [] : ['Service Category is required']
      this.error_msg.TypeErrors = this.form_data.service_type.length ? [] : ['Service Type is required']
      this.error_msg.ScreenTypeErrors = this.form_data.screen_type.id ? [] : ['Screen Type is required']
      this.error_msg.methodErrors = (this.form_data.method.length > 0) ? [] : ['Method is required']
      this.error_msg.linkFieldErrors = this.form_data.link_field ? [] : ['Default Link Field is required']
      this.error_msg.linkFormatErrors = this.form_data.link_format ? [] : ['Default Link Format is required']
      this.error_msg.defaultScreenErrors = this.form_data.default_screen ? [] : ['Default Screen is required']
      this.error_msg.defaultTokenModeErrors = this.form_data.default_token_mode ? [] : ['Default Token Mode is required']
      this.error_msg.invokingMethodErrors = this.form_data.invoking_method.id ? [] : ['Invoking Method is required']
      this.error_msg.defaultMethodErrors = this.form_data.default_method.id ? [] : ['Default Method is required']
      if (!this.formReady) {
        return Vue.notify({ text: 'Please fill the form correctly', type: 'error' })
      }
      if (!this.checkScreenFields) {
        return
      }
      if (!this.checkMethodFields) {
        return
      }
      if (this.form_data.attach_logo && (!this.Checkfile_upload)) {
        return
      }
      if (this.form_data.iptv && (!this.CheckIptv)) {
        return
      }
      if (this.form_data.encrypted && (this.form_data.drm_license_url === '')) {
        return this.error_msg.drmLicenseUrlErrors = this.form_data.drm_license_url ? [] : ['DRM License URL is required']
      }
      if (!REGEX.OnlyLower(this.form_data.brand)) {
        return this.error_msg.brandErrors.push('Only Lower Case is allowed')
      }
      if(this.provider_type == ''){
        return Vue.notify({ text: 'Please select the provider type', type: 'error' })
      }

      var categoryObject = JSON.stringify(this.form_data.service_category)
      var typeObject = JSON.stringify(this.form_data.service_type)
      var vm = this
      var payload = new FormData()
      if (this.form_data.iptv) {
        payload.append('iptv', this.form_data.iptv)
        payload.append('sms_host', this.form_data.sms_host)
        payload.append('sms_port', this.form_data.sms_port)
        payload.append('sms_token', this.form_data.sms_token)
        payload.append('drm_domain', this.form_data.drm_domain)
      }
      if (this.form_data.attach_logo) {
        payload.append('logo', this.logo_file[0])
        payload.append('attach_logo', this.form_data.attach_logo)
      }
      payload.append('brand', this.form_data.brand)
      payload.append('company_name', this.form_data.company_name)
      payload.append('service_category', categoryObject)
      payload.append('service_type', typeObject)
      payload.append('screen_type', this.form_data.screen_type.id)
      payload.append('default_method', this.form_data.default_method.id)
      payload.append('provider_type', this.provider_type)

      if (this.form_data.screen_type.id === 'Flat') {
        payload.append('screens', this.form_data.screens)
      } else {
        payload.append('mobile_screens', this.form_data.mobile_screens)
        payload.append('tv_screens', this.form_data.tv_screens)
      }
      if (this.form_data.method.length == 2) {
        payload.append('method', 'Link,Direct')
        linkpayload()
        directpayload()
      } else {
        if (this.form_data.method[0].id === 'Link') {
          payload.append('method', 'Link')
          linkpayload()
        } else {
          payload.append('method', 'Direct')
          directpayload()
        }
      }
      function linkpayload () {
        payload.append('web_link_field', vm.form_data.web_link_field)
        payload.append('web_link_format', vm.form_data.web_link_format)
        payload.append('tv_native_link_field', vm.form_data.tv_native_link_field)
        payload.append('tv_native_link_format', vm.form_data.tv_native_link_format)
        payload.append('mobile_native_link_field', vm.form_data.mobile_native_link_field)
        payload.append('mobile_native_link_format', vm.form_data.mobile_native_link_format)
        payload.append('invoking_method', vm.form_data.invoking_method.id)
        payload.append('allow_sites', vm.form_data.allow_sites)
        payload.append('link_field', vm.form_data.link_field)
        payload.append('link_format', vm.form_data.link_format)
        payload.append('default_screen', vm.form_data.default_screen)
        payload.append('default_token_mode', vm.form_data.default_token_mode.token_data)
      }
      function directpayload () {
        payload.append('direct_link_field', vm.form_data.direct_link_field)
        payload.append('direct_link_format', vm.form_data.direct_link_format)
        payload.append('play_format', vm.form_data.play_format.id)
        payload.append('encrypted', vm.form_data.encrypted)
        if (vm.form_data.encrypted) {
          payload.append('drm_license_url', vm.form_data.drm_license_url)
        }
      }
      var invoke_data = []
      var invokeFlag = true
      this.invokeFields.map(function (entry) {
        if (entry.org_name.org_name) {
          if (entry.org_name.org_name && entry.device_type && entry.method && entry.token_mode && entry.device_type) {
            var str = ''
            entry.mode.map(function (args) {
              if (str === '') {
                str = args.mode
              } else {
                str = str + ',' + args.mode
              }
            })
            var currentObj = {
              org_name: entry.org_name.org_name,
              org_id: entry.org_name.org_id,
              mode: str,
              device_type: entry.device_type.device_data,
              method: entry.invoking_method.method_data,
              token_mode: entry.token_mode.token_data,
              invoke_screen: entry.invoke_screen,
              service_category: entry.service_category.id,
              invoking_method: entry.method.id,
            }
            if (entry.service_type) {
              currentObj.service_type = entry.service_type.id
            }
            invoke_data.push(currentObj)
          } else {
            invokeFlag = false
            return Vue.notify({ text: 'Please fill the form correctly', type: 'error' })
          }
        }
      })
      if (invokeFlag) {
        payload.append('invoke_data', JSON.stringify(invoke_data))
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        this.$http.post(config.menu.host + '/provider', payload).then(responseData => {
          loader.hide()
          if (responseData && responseData.body) {
            Vue.notify({
              text: responseData.body,
              type: 'success',
            })
          }
          this.list()
        }, errResponse => {
          loader.hide()
          if (errResponse && errResponse.body) {
            Vue.notify({
              text: errResponse.body,
              type: 'error',
            })
            this.getAllCategory()
          }
        })
      }
    },
    isIptv (data) {
      this.form_data.iptv = !data
      this.iptvFlag = !!(this.form_data.iptv)
    },
    isLogo (data) {
      this.form_data.attach_logo = !data
      this.logoFlag = !!(this.form_data.attach_logo)
    },
    isEncrypt (data) {
      this.form_data.encrypted = !data
      this.encryptedFlag = !!(this.form_data.encrypted)
    },
    screenType (screen_type) {
      if (screen_type.id === 'Flat') {
        this.flatFlag = true
        this.variableFlag = false
      } else {
        this.flatFlag = false
        this.variableFlag = true
      }
    },
    methodType (data) {
      if (data.length == 2) {
        this.linkFlag = true
        this.directFlag = true
      } else {
        if (data[0].id === 'Link') {
          this.linkFlag = true
          this.directFlag = false
        } else {
          this.linkFlag = false
          this.directFlag = true
        }
      }
    },
    updateConfig () {
      var vm = this
      this.reset()
      this.error_msg.brandErrors = this.form_data.brand ? [] : ['Brand is required']
      this.error_msg.companyErrors = this.form_data.company_name ? [] : ['Company Name is required']
      this.error_msg.CategoryErrors = this.form_data.service_category.length ? [] : ['Service Category is required']
      this.error_msg.TypeErrors = this.form_data.service_type.length ? [] : ['Service Type is required']
      this.error_msg.ScreenTypeErrors = (this.form_data.screen_type.id || this.form_data.screen_type) ? [] : ['Screen Type is required']
      this.error_msg.methodErrors = (this.form_data.method.id || this.form_data.method) ? [] : ['Method is required']
      this.error_msg.linkFieldErrors = this.form_data.link_field ? [] : ['Default Link Field is required']
      this.error_msg.linkFormatErrors = this.form_data.link_format ? [] : ['Default Link Format is required']
      this.error_msg.defaultScreenErrors = this.form_data.default_screen ? [] : ['Default Screen is required']
      var defaultTokenMode = this.form_data.default_token_mode && (this.form_data.default_token_mode.token_data || this.form_data.default_token_mode)
      this.error_msg.defaultTokenModeErrors = defaultTokenMode ? [] : ['Default Token Mode is required']
      var defaultMethod = this.form_data.default_method.id ? this.form_data.default_method.id : this.form_data.default_method
      this.error_msg.defaultMethodErrors = defaultMethod ? [] : ['Default Method is required']
      if (!this.formReady) {
        return Vue.notify({ text: errResponse.body, type: 'error' })
      }

      if (!this.checkScreenFields) {
        return
      }

      if (!this.checkMethodFields) {
        return
      }
      if (!REGEX.OnlyLower(this.form_data.brand)) {
        return this.error_msg.brandErrors.push('Only Lower Case is allowed')
      }
      if (this.form_data.iptv && (!this.CheckIptv)) {
        return
      }

      var screenType = this.form_data.screen_type.id || this.form_data.screen_type
      var Method = this.form_data.method.id || this.form_data.method
      var invokingMethod = this.form_data.invoking_method && (this.form_data.invoking_method.id || this.form_data.invoking_method)

      if (typeof (this.form_data.service_category) === 'object') {
        var categoryObject = JSON.stringify(this.form_data.service_category)
        var typeObject = JSON.stringify(this.form_data.service_type)
      } else {
        var categoryObject = this.form_data.service_category
        var typeObject = this.form_data.service_type
      }

      this.form_data.service_type = typeObject
      this.form_data.service_category = categoryObject

      if (this.form_data.encrypted && (this.form_data.drm_license_url === '')) {
        return this.error_msg.drmLicenseUrlErrors = this.form_data.drm_license_url ? [] : ['DRM License URL is required']
      }

      var payload = new FormData()
      payload.append('iptv', this.form_data.iptv)
      payload.append('sms_host', this.form_data.sms_host)
      payload.append('sms_port', this.form_data.sms_port)
      payload.append('sms_token', this.form_data.sms_token)
      payload.append('attach_logo', this.form_data.attach_logo)
      payload.append('default_method', defaultMethod)
      payload.append('drm_domain', this.form_data.drm_domain)
      if (this.logo_file[0]) {
        payload.append('logo', this.logo_file[0])
      }
      payload.append('brand', this.form_data.brand)
      payload.append('company_name', this.form_data.company_name)
      payload.append('service_category', categoryObject)
      payload.append('service_type', typeObject)
      payload.append('screen_type', screenType)
      payload.append('provider_type', this.provider_type)

      if (screenType === 'Flat') {
        payload.append('screens', this.form_data.screens)
      } else {
        payload.append('mobile_screens', this.form_data.mobile_screens)
        payload.append('tv_screens', this.form_data.tv_screens)
      }

      if (this.form_data.method.length == 2) {
        payload.append('method', 'Link,Direct')
        linkpayload()
        directpayload()
      } else {
        if (this.form_data.method[0].id === 'Link') {
          payload.append('method', 'Link')
          linkpayload()
        } else {
          payload.append('method', 'Direct')
          directpayload()
        }
      }
      function linkpayload () {
        payload.append('web_link_field', vm.form_data.web_link_field)
        payload.append('web_link_format', vm.form_data.web_link_format)
        payload.append('tv_native_link_field', vm.form_data.tv_native_link_field)
        payload.append('tv_native_link_format', vm.form_data.tv_native_link_format)
        payload.append('mobile_native_link_field', vm.form_data.mobile_native_link_field)
        payload.append('mobile_native_link_format', vm.form_data.mobile_native_link_format)
        payload.append('allow_sites', vm.form_data.allow_sites)
        payload.append('link_field', vm.form_data.link_field)
        payload.append('link_format', vm.form_data.link_format)
        payload.append('invoking_method', invokingMethod)
        payload.append('default_screen', vm.form_data.default_screen)
        payload.append('default_token_mode', defaultTokenMode)
      }
      function directpayload () {
        payload.append('direct_link_field', vm.form_data.direct_link_field)
        payload.append('direct_link_format', vm.form_data.direct_link_format)
        payload.append('play_format', vm.form_data.play_format.id)
        payload.append('encrypted', vm.form_data.encrypted)
        if (vm.form_data.encrypted) {
          payload.append('drm_license_url', vm.form_data.drm_license_url)
        }
      }
      var invoke_data = []
      var invokeFlag = true
      if (this.invokeFields[0].org_name) {
        this.invokeFields.map(function (data) {
          if (data.org_name.org_name && data.device_type && data.method && data.token_mode && data.invoke_screen) {
            var str = ''
            data.mode.map(function (args) {
              if (str === '') {
                str = args.mode
              } else {
                str = str + ',' + args.mode
              }
            })
            var currentObj = {
              org_name: data.org_name.org_name,
              org_id: data.org_name.org_id,
              mode: str,
              device_type: data.device_type.device_data,
              method: data.invoking_method.method_data,
              token_mode: data.token_mode.token_data,
              invoke_screen: data.invoke_screen,
              provider_id: vm.provider_id,
              service_category: data.service_category.id,
              invoking_method: data.method.id,
            }
            if (data.service_type) {
              currentObj.service_type = data.service_type.id
            }
            invoke_data.push(currentObj)
          } else if (data.org_name && data.device_type && data.method && data.token_mode && data.invoke_screen) {
            var currentObj = {
              org_name: data.org_name,
              org_id: data.org_id,
              mode: data.mode,
              device_type: data.device_type,
              method: data.invoking_method,
              token_mode: data.token_mode,
              invoke_screen: data.invoke_screen,
              provider_id: vm.provider_id,
              service_category: data.service_category,
              invoking_method: data.method,
            }

            if (data.service_type) {
              currentObj.service_type = data.service_type.id
            }

            invoke_data.push(currentObj)
          } else {
            invokeFlag = false
            return Vue.notify({ text: 'Please fill the form correctly', type: 'error' })
          }
        })
      }
      if (invokeFlag) {
        payload.append('invoke_data', JSON.stringify(invoke_data))
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        this.$http.put(config.menu.host + '/provider/' + this.provider_id, payload).then(responseData => {
          loader.hide()
          if (responseData && responseData.body) {
            Vue.notify({
              text: responseData.body,
              type: 'success',
            })
          }
          this.list()
        }, errResponse => {
          loader.hide()
          if (errResponse && errResponse.body) {
            Vue.notify({
              text: errResponse.body,
              type: 'error',
            })
            this.getAllCategory()
          }
        })
      }
    },
    getAllCategory () {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/servicecategory').then(response => {
        loader.hide()
        var category_array = []
        if (response.body.length > 0) {
          response.body.map(function (item) {
            var id = item.id
            var type = item.short_code
            var payload = {
              id: type,
            }
            category_array.push(payload)
          })
        } else {
          category_array = []
        }
        this.category_data = category_array
      })
    },
    getAllType () {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/servicetype').then(response => {
        loader.hide()
        var typeArray = []
        if (response.body.length > 0) {
          response.body.map(function (item) {
            var id = item.id
            var type = item.short_code
            var payload = {
              id: type,
            }
            typeArray.push(payload)
          })
        } else {
          typeArray = []
        }
        this.type_data = typeArray
      })
    },
    add () {
      this.reset()
      this.getAllCategory()
      this.getAllType()
      this.title = 'Create service provider'
      this.form_data.brand = ''
      this.form_data.company_name = ''
      this.form_data.screen_type = ''
      this.form_data.screens = ''
      this.form_data.tv_screens = ''
      this.form_data.mobile_screens = ''
      this.form_data.method = []
      this.form_data.direct_link_field = ''
      this.form_data.direct_link_format = ''
      this.form_data.direct_format = ''
      this.form_data.web_link_field = ''
      this.form_data.web_link_format = ''
      this.form_data.tv_native_link_field = ''
      this.form_data.tv_native_link_format = ''
      this.form_data.mobile_native_link_field = ''
      this.form_data.mobile_native_link_format = ''
      this.form_data.play_format = ''
      this.form_data.invoking_method = ''
      this.form_data.drm_license_url = ''
      this.form_data.allow_sites = ''
      this.form_data.iptv = false
      this.form_data.sms_host = ''
      this.form_data.sms_token = ''
      this.form_data.sms_port = ''
      this.form_data.drm_domain = ''
      this.form_data.attach_logo = false
      this.form_data.encrypted = false
      this.logo_file = []
      this.form_data.service_category = []
      this.form_data.service_type = []
      this.invokeFields = [{ org_name: '', mode: [], device_type: '', method: '', token_mode: '', invoke_screen: 1, service_category: '', invoking_method: '' }]
      this.method_arr = [{ id: 'Link' }, { id: 'Direct' }]
      this.form_data.link_field = ''
      this.form_data.link_format = ''
      this.form_data.default_screen = ''
      this.form_data.default_token_mode = ''
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
      this.linkFlag = false
      this.directFlag = false
      this.flatFlag = false
      this.variableFlag = false
      this.logoFlag = false
      this.iptvFlag = false
      this.error_msg.defaultScreenErrors = []
      this.error_msg.defaultTokenModeErrors = []
      this.error_msg.linkFormatErrors = []
      this.error_msg.linkFieldErrors = []
      this.error_msg.invokingMethodErrors = []
      this.error_msg.directLinkFormatErrors = []
      this.error_msg.directLinkFieldErrors = []
      this.error_msg.playFormatErrors = []
      this.provider_type = ''
    },
    edit (row) {
      var vm = this
      this.reset()
      this.getAllCategory()
      this.getAllType()
      var categoryJson = []; var typeJson = []
      categoryJson = JSON.parse(row.service_category)
      typeJson = JSON.parse(row.service_type)
      this.title = 'update service provider'
      this.provider_id = row.provider_id
      this.form_data.brand = row.brand
      this.provider_type = row.provider_type
      this.form_data.company_name = row.company_name
      this.form_data.service_category = categoryJson
      this.form_data.service_type = typeJson
      this.form_data.screen_type = row.screen_type
      this.form_data.default_method = row.default_method ? row.default_method : ''
      var methodarr = row.method.split(',')
      var method_input = []
      methodarr.map(function (value) {
        method_input.push({ id: value })
      })
      this.form_data.method = method_input
      if (row.screen_type === 'Flat') {
        this.flatFlag = true
        this.form_data.screens = row.screens
      } else {
        this.variableFlag = true
        this.form_data.mobile_screens = row.mobile_screens
        this.form_data.tv_screens = row.tv_screens
      }
      if (method_input.length == 2) {
        this.directFlag = true
        this.linkFlag = true
        getDirectData()
        getLinkData()
      } else {
        if (method_input[0].id === 'Direct') {
          this.directFlag = true
          this.linkFlag = false
          getDirectData()
        } else {
          this.linkFlag = true
          this.directFlag = false
          getLinkData()
        }
      }
      function getLinkData () {
        vm.form_data.link_field = (row.link_accesses[0].link_field) ? row.link_accesses[0].link_field : ''
        vm.form_data.link_format = (row.link_accesses[0].link_format) ? row.link_accesses[0].link_format : ''
        vm.form_data.mobile_native_link_field = row.link_accesses[0].mobile_native_link_field
        vm.form_data.mobile_native_link_format = row.link_accesses[0].mobile_native_link_format
        vm.form_data.tv_native_link_field = row.link_accesses[0].tv_native_link_field
        vm.form_data.tv_native_link_format = row.link_accesses[0].tv_native_link_format
        vm.form_data.web_link_field = row.link_accesses[0].web_link_field
        vm.form_data.web_link_format = row.link_accesses[0].web_link_format
        vm.form_data.mobile_native_link_field = row.link_accesses[0].mobile_native_link_field
        vm.form_data.mobile_native_link_format = row.link_accesses[0].mobile_native_link_format
        vm.form_data.invoking_method = (row.link_accesses[0].invoking_method) ? row.link_accesses[0].invoking_method : ''
        vm.form_data.default_token_mode = (row.link_accesses[0].default_token_mode) ? row.link_accesses[0].default_token_mode : ''
        vm.form_data.allow_sites = row.link_accesses[0].allow_sites
        if (row.invoking_methods && row.invoking_methods.length > 0) {
          row.invoking_methods.map(function (data) {
        	  var swap_method=data.invoking_method;
        	  var swap_invoke=data.method
            data.showFlag = true
            var input = ''
            if (typeof (data.mode) === 'object') {
              data.mode.map(function (args) {
                if (input === '') {
                  input = args.mode
                } else {
                  input = input + ', ' + args.mode
                }
              })
              data.mode = input
            }
	          data.method = swap_method
            data.invoking_method = swap_invoke
          })
          vm.invokeFields = row.invoking_methods
        } else {
          vm.invokeFields = [{ org_name: '', mode: [], device_type: '', method: '', token_mode: '', invoke_screen: 1, service_category: '', invoking_method: '' }]
        }
      }
      function getDirectData () {
        if (row.direct_accesses.length > 0) {
        vm.form_data.direct_link_field = row.direct_accesses[0].link_field
        vm.form_data.direct_link_format = row.direct_accesses[0].link_format
        vm.form_data.play_format = row.direct_accesses[0].play_format
        vm.form_data.encrypted = row.direct_accesses[0].encrypted
        vm.encryptedFlag = !!(vm.form_data.encrypted)
        vm.form_data.drm_license_url = row.direct_accesses[0].drm_license_url
       }
      }
      this.form_data.attach_logo = !!(row.attach_logo)
      this.form_data.iptv = !!row.iptv
      this.iptvFlag = !!(row.iptv)
      this.form_data.sms_host = row.sms_host
      this.form_data.sms_port = row.sms_port
      this.form_data.sms_token = row.sms_token
      this.form_data.drm_domain = row.drm_domain
      if (this.form_data.attach_logo) {
        this.logoFlag = true
      } else {
        this.logoFlag = false
      }
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
    },
    reset () {
      this.type_data = []
      this.category_data = []
      this.error_msg.typeErrors = []
      this.error_msg.brandErrors = []
      this.error_msg.companyErrors = []
      this.error_msg.CategoryErrors = []
    },
    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.readItems()
      this.error_msg.TypeErrors = []
      this.error_msg.ScreenTypeErrors = []
      this.error_msg.methodErrors = []
      this.error_msg.defaultMethodErrors = []
      this.error_msg.linkFieldErrors = []
      this.error_msg.linkFormatErrors = []
      this.error_msg.invokingMethodErrors = []
      this.error_msg.defaultTokenModeErrors = []
      this.error_msg.defaultScreenErrors = []
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
}
</script>
<style lang="scss">

.content-th {
  min-width: 100px;
  max-width: 100px;
}

.radio_options {
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.text_box {
  width: 937px;
  height: 101px;
  border-color: black;
  border-radius: 10px;
  resize: none;
}

.fields {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 1rem;
  margin-top: 2rem;
}

.content_width {
  width: 30px;
  height: 30px;
}
</style>
